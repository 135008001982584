<template>
  <div class="homePage">
    <MainBanner class="d-none d-md-flex" :title="mainTitle" />
    <a
      href="https://wa.me/5491169761773"
      target="_blank"
      color="#4dc247"
      class="whatsapp"
    >
      <img width="50" src="/img/whatsapp-logo.png" alt="" />
    </a>
    <img class="w-100 montserrat-regular d-md-none" :src="images.mainMobile" />
    <section>
      <div class="col-12 second-section-text py-5 montserrat-regular">
        <div class="heading text-center">
          <h2>FORTALEZA EN SEGUROS</h2>
          <h5 class="color-gray mt-3">
            Nos especializamos en la gestión de riesgos e ingeniería de costos,
            enfocados a empresas medianas y grandes.
          </h5>
        </div>
      </div>
    </section>
    <section class="px-4 col-lg-9 m-auto">
      <div class="row text-center">
        <div class="col-md-4 col">
          <div class="d-flex align-items-center flex-column mb-5">
            <a href="/productores">
              <img
                width="200"
                style="margin-bottom: 1em"
                :src="images.iconOne"
                alt=""
              />
            </a>
            <label class="second-title montserrat-bold">
              <span>¿Querés ser productor?</span>
            </label>
          </div>
        </div>
        <div class="col-md-4 col">
          <div class="d-flex align-items-center flex-column mb-5">
            <a href="/contactos">
              <img
                width="200"
                style="margin-bottom: 1em"
                :src="images.iconTwo"
                alt=""
              />
            </a>
            <label class="second-title montserrat-bold">
              <span>¿Necesitás un seguro?</span>
            </label>
          </div>
        </div>
        <div class="col-md-4 col">
          <div class="d-flex align-items-center flex-column mb-5">
            <a href="/contactos">
              <img
                width="200"
                style="margin-bottom: 1em"
                :src="images.iconThree"
                alt=""
              />
            </a>
            <label class="second-title montserrat-bold">
              <span>Estamos para apoyarte</span>
            </label>
          </div>
        </div>
      </div>
    </section>
    <section class="container text-center col-lg-9 montserrat-regular">
      <div class="container py-4 text-center">
        <div class="col heading text-center">
          <h2>
            <span style="background-color: var(--light-orange)">
              ESCUCHAMOS Y RESPONDEMOS
            </span>
          </h2>
          <p class="intro"></p>
        </div>
      </div>
      <!-- <h2 class="orange-title">TENEMOS <span>RITUALES_____</span></h2>
      <h5 class="py-4">Escuchamos y respondemos</h5> -->
      <div class="row py-4">
        <div class="col-md-6 col-lg-4 col-sm-12 montserrat-medium">
          <div class="boxBlue d-flex align-items-center flex-column mb-5">
            <img class="w-100" :src="images.boxOne" alt="" />
          </div>
        </div>
        <div class="col-md-6 col-lg-4 col-sm-12 montserrat-medium">
          <div class="boxBlue d-flex align-items-center flex-column mb-5">
            <img class="w-100" :src="images.boxTwo" alt="" />
          </div>
        </div>
        <div class="col-md-6 col-lg-4 col-sm-12">
          <div class="boxBlue d-flex align-items-center flex-column mb-5">
            <img class="w-100" :src="images.boxThree" alt="" />
          </div>
        </div>
      </div>
    </section>
    <Banner class="section-tribe" :banner="bannerText" />
    <section class="container col-lg-9 py-4 text-center montserrat-regular">
      <div class="container py-4 text-center">
        <div class="col heading text-center">
          <h2>
            <span style="background-color: var(--light-orange)">
              NUESTRO RITUAL ES CUIDARTE
            </span>
          </h2>
          <p class="intro">
            Fusionamos nuestras fuerzas, energías, conocimientos y habilidades
            para que cada día cuentes con la seguridad que estás necesitando.
          </p>
        </div>
      </div>
      <!-- <div class="col text-center montserrat-semibol">
        <h2 class="orange-title">NUESTRO RITUAL ES <span>CUIDARTE</span></h2>
      </div>
      <div class="py-4 col-md-8 offset-md-2">
        <h5>
          Fusionamos nuestras fuerzas, energías, conocimientos y habilidades
          para que cada dia cuentes con la seguridad que estas necesitando.
        </h5>
      </div> -->
      <div class="row py-4 spartan-regular">
        <div class="col-md-6 col-lg-4 col-sm-12 spartan-regular">
          <div class="boxBlue d-flex align-items-center flex-column mb-5">
            <img class="w-100" :src="images.boxCareOne" alt="" />
          </div>
        </div>
        <div class="col-md-6 col-lg-4 col-sm-12">
          <div class="boxBlue d-flex align-items-center flex-column mb-5">
            <img class="w-100" :src="images.boxCareTwo" alt="" />
          </div>
        </div>
        <div class="col-md-6 col-lg-4 col-sm-12">
          <div class="boxBlue d-flex align-items-center flex-column mb-5">
            <img class="w-100" :src="images.boxCareThree" alt="" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MainBanner from "@/components/MainBanner.vue";
import Banner from "@/components/Banner.vue";

import iconOne from "@/assets/icon-fortaleza-seguro-one(1).png";
import iconTwo from "@/assets/icon-fortaleza-seguro-two(1).png";
import iconThree from "@/assets/icon-fortaleza-seguro-three(1).png";

import boxOne from "@/assets/card-ritual-one-01.png";
import boxTwo from "@/assets/card-ritual-two.png";
import boxThree from "@/assets/card-ritual-three-01.png";

import boxCareOne from "@/assets/card-ritual-cuidar-one-01.png";
import boxCareTwo from "@/assets/card-ritual-cuidar-two-01.png";
import boxCareThree from "@/assets/card-ritual-cuidar-three-01.png";

import bannerImage from "@/assets/second-banner-home-01.png";
import mainMobile from "@/assets/mobile/zama-mobile.png";

// import secondBannerImage from "@/assets/second-banner-somos-zama-01.png";
import mainBannerImage from "@/assets/main-banner-home-01.png";

export default {
  name: "WeAre",
  components: {
    MainBanner,
    Banner,
  },
  data() {
    return {
      images: {
        iconOne,
        iconTwo,
        iconThree,
        boxOne,
        boxTwo,
        boxThree,
        boxCareOne,
        boxCareTwo,
        boxCareThree,
        mainMobile,
      },
      mainTitle: {
        first: "SABEMOS",
        second: "ESCUCHAR",
        subtext: "Somos un broker que está siempre cerca tuyo",
        img: mainBannerImage,
        bgColor: "var(--dark-blue)",
      },
      bannerText: {
        goto: "productores",
        gotoId: "#vitaeForm",
        bannerTitleOne: "Somos tribu,",
        bannerTitleSecond: "somos equipo.",
        bannerText:
          "Si tenés un perfil comercial, emprendedor y querés manejar tu propia agenda y horarios, podés convertirte en TU PROPIO JEFE.",
        img: bannerImage,
        bgColor: "var(--secondary-blue)",
        btn: "UNITE",
      },
    };
  },
};
</script>
<style>
.homePage .main-title span {
  background: #0c436a;
}
</style>
